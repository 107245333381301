import { useContext } from 'react'
import amplitudeContext, { AmplitudeContext } from './amplitudeContext'

export type Amplitude = AmplitudeContext

const useAmplitude = (): Amplitude => {
    return useContext(amplitudeContext)
}

export default useAmplitude
