import Image from 'next/image'
import Link from 'next/link'
import { useRef, useState } from 'react'
import { AiOutlineUser } from 'react-icons/ai'
import { IoIosMenu } from 'react-icons/io'
import { useAuth } from 'src/contexts/AuthContext'
import { isServer } from '@util/config'
import { useMeQuery } from '@apollo/queries/useMeQuery'
import { useClickOutside } from '../../../hooks/useClickOutside'
import { useRouter } from 'next/router'
import useAmplitude from '../../../modules/amplitude/useAmplitude'
import UniversalCookiesManager from '../../../modules/cookiesManager/UniversalCookiesManager'

type AppAuthProps = {
    isScrolled: boolean
}

export const AppAuth: React.FC<AppAuthProps> = ({ isScrolled }) => {
    const [profileDropdown, setProfileDropdown] = useState(false)

    const router = useRouter()
    const menuRef = useRef(null)
    const { isLoggedIn, logOut } = useAuth()

    useClickOutside(menuRef, () => {
        setProfileDropdown(false)
    })
    const { data, loading, error } = useMeQuery({
        skip: isServer || !isLoggedIn,
        fetchPolicy: 'network-only',
    })

    if (loading || error) return null

    return (
        <>
            <div ref={menuRef} className="z-50 flex items-start justify-end mr-4 xl:mr-12">
                {isLoggedIn ? (
                    <div
                        onClick={(): void => setProfileDropdown(!profileDropdown)}
                        className={`${
                            isScrolled
                                ? 'text-gray-900 border border-gray-300 hover:shadow-md'
                                : 'text-white border border-white hover:bg-gray-100 hover:bg-opacity-15'
                        } px-2 py-2  font-semibold  rounded-full outline-none  flex justify-between items-center relative`}
                    >
                        <IoIosMenu className={`${isScrolled ? 'text-gray-600' : 'text-white'} w-4 h-4 mr-2`} />
                        {data?.me?.avatar ? (
                            <img className="object-cover w-6 h-6 rounded-full" src={data?.me?.avatar} alt="" />
                        ) : (
                            <div
                                className={`${
                                    isScrolled
                                        ? 'text-gray-900 border border-gray-300'
                                        : 'text-white border border-white '
                                } px-1 py-1  font-semibold  rounded-full outline-none   flex w-8 h-8`}
                            >
                                <AiOutlineUser
                                    className={`${isScrolled ? 'text-gray-600' : 'text-white'} w-full h-full `}
                                />
                            </div>
                        )}

                        <div
                            className={`absolute top-0 bg-white rounded-lg  p-4 shadow-md hover:shadow-lg text-gray-900 font-normal mt-4 dropdown ${
                                !profileDropdown ? 'hidden' : 'block'
                            }`}
                        >
                            <Link href="/profil">
                                <a className="block cursor-pointer hover:text-red-500">Fiókbeállítások</a>
                            </Link>
                            <Link href="/profil/foglalasaim">
                                <a className="block mt-5 cursor-pointer hover:text-red-500">Foglalásaim</a>
                            </Link>
                            <button onClick={logOut} className="block mt-5 cursor-pointer hover:text-red-500">
                                Kijelentkezés
                            </button>
                        </div>
                    </div>
                ) : (
                    <>
                        <Link href="/register">
                            <a
                                className={`${
                                    isScrolled ? 'text-gray-900' : 'text-white hover:bg-opacity-15'
                                } px-3 py-2 lg:mr-2 text-sm font-semibold  rounded-lg focus:outline-none hover:bg-gray-100  lg:block hidden lg:text-xs mt-2`}
                            >
                                Regisztráció
                            </a>
                        </Link>
                        <Link href="/login">
                            <a
                                className={`${
                                    isScrolled
                                        ? 'text-gray-900 border border-gray-900'
                                        : 'text-white border border-white hover:bg-opacity-15'
                                } px-3 py-2 lg:text-xs font-semibold  rounded-lg focus:outline-none hover:bg-gray-100 hidden lg:block mt-2`}
                            >
                                Bejelentkezés
                            </a>
                        </Link>
                        <div
                            onClick={(): void => setProfileDropdown(!profileDropdown)}
                            className={`${
                                isScrolled
                                    ? 'text-gray-900 border border-gray-300 hover:shadow-md'
                                    : 'text-white border border-white hover:bg-gray-100 hover:bg-opacity-15'
                            } px-2 py-2  font-semibold  rounded-full outline-none  flex justify-between items-center relative lg:hidden`}
                        >
                            <IoIosMenu className={`${isScrolled ? 'text-gray-600' : 'text-white'} w-4 h-4 mr-2`} />
                            <div
                                className={`${
                                    isScrolled
                                        ? 'text-gray-900 border border-gray-300'
                                        : 'text-white border border-white '
                                } px-1 py-1  font-semibold  rounded-full outline-none   flex`}
                            >
                                <AiOutlineUser className={`${isScrolled ? 'text-gray-600' : 'text-white'} w-4 h-4 `} />
                            </div>
                            <div
                                className={`absolute top-0 bg-white rounded-lg  p-6 shadow-md hover:shadow-lg text-gray-900 font-normal mt-4 dropdown shadow:md ${
                                    !profileDropdown ? 'hidden' : 'block'
                                }`}
                            >
                                <a href="/register" className="block mb-4 text-sm text-gray-600 hover:text-gray-900">
                                    Regisztráció
                                </a>
                                <a href="/login" className="block text-sm text-gray-600 hover:text-gray-900">
                                    Bejelentkezés
                                </a>
                            </div>
                        </div>
                    </>
                )}
                <style jsx>{`
                     {
                        .dropdown {
                            top: 70%;
                            right: 1%;
                        }
                    }
                `}</style>
            </div>
        </>
    )
}
