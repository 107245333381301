import { QueryHookOptions } from '@apollo/client'
import { useRouter } from 'next/router'
import { MeQuery, MeQueryVariables, useMeQuery as useMeQueryFromLib } from '../../graphql/gqlHooks'
import { useAuth } from '../../contexts/AuthContext'
import useAmplitude from '../../modules/amplitude/useAmplitude'
import UniversalCookiesManager from '../../modules/cookiesManager/UniversalCookiesManager'

export const useMeQuery = (
    baseOptions?: QueryHookOptions<MeQuery, MeQueryVariables>,
): ReturnType<typeof useMeQueryFromLib> => {
    const { logOut } = useAuth()
    const router = useRouter()
    const { amplitudeInstance } = useAmplitude()
    return useMeQueryFromLib({
        ...baseOptions,
        onError: () => {
            logOut()
            router.push('/')
        },
        onCompleted: ({ me }) => {
            amplitudeInstance.setUserId(me.id)
            const cookie = new UniversalCookiesManager()
            cookie.patchUserData({ id: me.id })
        },
    })
}
