import { FaBuffer } from 'react-icons/fa'
import Link from 'next/link'

type AppLogoProps = {
    isScrolled: boolean
}

export const AppLogo: React.FC<AppLogoProps> = ({ isScrolled }) => {
    return (
        <div className="flex items-start flex-1 xl:ml-12 lg:ml-4 md:ml-2">
            <Link href="/">
                <a className="flex items-center cursor-pointer md:mb-0">
                    <FaBuffer
                        className={`${
                            isScrolled ? 'text-red-500' : 'text-white'
                        } w-10 h-10 p-2 text-white rounded-full`}
                    />
                    <span
                        className={`${
                            isScrolled ? 'text-red-500' : 'text-white'
                        } ml-1 text-2xl font-semibold hidden lg:block`}
                    >
                        Foglaljmost
                    </span>
                </a>
            </Link>
        </div>
    )
}
